<template>
  <div class="pd-20">
    <a-page-header class="pd-0">
      <div slot="title">
        <h1><a-icon class="gradient" type="audit" /> Vouchers</h1>
      </div>
      <div slot="extra">
        <a-button
          class="ml-10"
          type="primary"
          ghost
          @click="openImportVoucher = true"
        >
          <a-icon type="upload" /> IMPORTAR CSV
        </a-button>

        <a-button
          class="ml-10"
          type="primary"
          ghost
          @click="openCreateVoucher = true"
        >
          <a-icon type="audit" /> NOVO VOUCHER
        </a-button>
      </div>
    </a-page-header>

    <a-tabs type="card" :default-active-key="activeTab" @change="onChangeTabs">
      <a-tab-pane key="Ativo" tab="ATIVO" />
      <a-tab-pane key="Pendente" tab="PENDENTE" />
      <a-tab-pane key="Finalizado" tab="FINALIZADO" />
      <a-tab-pane key="Auditoria" tab="AUDITORIA" />
      <a-tab-pane key="Cartão de Crédito" tab="CARTÃO DE CRÉDITO" />
      <a-tab-pane key="Perdido" tab="PERDIDO" />
    </a-tabs>

    <a-row class="haya-panels mb-20 mt-5" :gutter="20">
      <a-col :span="2">
        <div class="box">
          <div class="title"></div>
          <div class="total">{{ milesCardVouchers.meta.total }}</div>
          <div class="txt">Vouchers</div>
          <div class="footer">
            Total: {{ milesCardVouchers.meta.totalGeneral }}
          </div>
        </div>
      </a-col>

      <a-col v-if="milesCardVouchers.meta.totalBRL != '0,00'" :span="6">
        <div class="box">
          <div class="title"></div>
          <div class="total cblack">
            R$ {{ milesCardVouchers.meta.totalBRL }}
          </div>
          <div class="txt">Total Reais</div>
          <div class="footer">
            <a-row type="flex" justify="space-between">
              <a-col style="color: #ffbdbd">
                Utilizado: R$
                <b> {{ milesCardVouchers.meta.totalSpentBRL }}</b>
              </a-col>

              <a-col style="color: #e4ffd9">
                Disponível: R$
                <b> {{ milesCardVouchers.meta.totalAvailableBRL }}</b>
              </a-col>
            </a-row>
          </div>
        </div>
      </a-col>

      <a-col v-if="milesCardVouchers.meta.totalEUR != '0,00'" :span="6">
        <div class="box">
          <div class="title"></div>
          <div class="total cblack">
            € {{ milesCardVouchers.meta.totalEUR }}
          </div>
          <div class="txt">Total Euros</div>
          <div class="footer">
            <a-row type="flex" justify="space-between">
              <a-col style="color: #ffbdbd">
                Utilizado: €
                <b> {{ milesCardVouchers.meta.totalSpentEUR }}</b>
              </a-col>
              <a-col style="color: #e4ffd9">
                Disponível: €
                <b> {{ milesCardVouchers.meta.totalAvailableEUR }}</b>
              </a-col>
            </a-row>
          </div>
        </div>
      </a-col>

      <a-col v-if="milesCardVouchers.meta.totalUSD != '0,00'" :span="6">
        <div class="box">
          <div class="title"></div>
          <div class="total cblack">
            U$ {{ milesCardVouchers.meta.totalUSD }}
          </div>
          <div class="txt">Total Dolares</div>
          <div class="footer">
            <a-row type="flex" justify="space-between">
              <a-col style="color: #ffbdbd">
                Utilizado: U$
                <b> {{ milesCardVouchers.meta.totalSpentUSD }}</b>
              </a-col>
              <a-col style="color: #e4ffd9">
                Disponível: U$
                <b> {{ milesCardVouchers.meta.totalAvailableUSD }}</b>
              </a-col>
            </a-row>
          </div>
        </div>
      </a-col>
    </a-row>

    <a-collapse
      class="travel-filters expandable mt-20 mb-10"
      activeKey="0"
      expandIconPosition="right"
    >
      <a-collapse-panel key="1">
        <template slot="header">
          <a-icon type="filter" class="mr-5" /> FILTRAR
        </template>

        <a-row class="mt-5 mb-0" :gutter="20">
          <a-col :span="2">
            <div class="travel-input">
              <label for class="filled">ID </label>
              <a-input
                placeholder="Digite"
                v-model="milesCardVouchers.filters.id"
                @change="getVouchers()"
              />
            </div>
          </a-col>

          <a-col :span="2">
            <div class="travel-input">
              <label for class="filled">ID do Voyage</label>
              <a-input
                placeholder="Digite"
                v-model="milesCardVouchers.filters.voyage_id"
                @change="getVouchers()"
              />
            </div>
          </a-col>

          <a-col :span="4">
            <div class="travel-input">
              <label for class="filled">Cliente</label>
              <a-input
                placeholder="Digite"
                v-model="milesCardVouchers.filters.client_name"
                @change="getVouchers()"
              />
            </div>
          </a-col>

          <a-col :span="4">
            <div class="travel-input">
              <label for class="filled">Código do Voucher</label>
              <a-input
                placeholder="Digite"
                v-model="milesCardVouchers.filters.voucher_code"
                @change="getVouchers()"
              />
            </div>
          </a-col>

          <a-col :span="4">
            <div class="travel-input">
              <label for class="filled">Data de recebimento</label>
              <a-date-picker
                v-model="milesCardVouchers.filters.receipt_date"
                format="DD/MM/YYYY"
                valueFormat="YYYY-MM-DD"
                @change="getVouchers()"
              />
            </div>
          </a-col>

          <a-col :span="4">
            <div class="travel-input">
              <label for class="filled">Moeda</label>
              <a-select
                class="travel-input"
                placeholder="Selecione"
                mode="multiple"
                optionFilterProp="txt"
                v-model="milesCardVouchers.filters.currency.selected"
                show-search
                style="width: 100%"
                @change="getVouchers()"
              >
                <a-select-option
                  v-for="(item, index) of milesCardVouchers.filters.currency
                    .list"
                  :key="index"
                  :value="item.name"
                  :txt="item.name"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>

          <a-col v-if="$root.isAdmin()" :span="4">
            <div class="travel-input">
              <label for class="filled">Emissor</label>
              <a-select
                class="travel-input"
                placeholder="Selecione um ou mais usuários"
                mode="multiple"
                optionFilterProp="txt"
                v-model="milesCardVouchers.filters.users.selected"
                show-search
                style="width: 100%"
                @change="getVouchers()"
              >
                <a-select-option
                  v-for="(item, index) of milesCardVouchers.filters.users.list"
                  :key="index"
                  :value="item.id"
                  :txt="item.first_name"
                >
                  {{ item.id }} - {{ item.first_name }}
                  {{ item.last_name }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>

          <a-col :span="8">
            <div class="travel-input">
              <label for class="filled">Vencimentos entre:</label>

              <a-range-picker
                v-model="milesCardVouchers.filters.expires.selected"
                :format="['DD/MM/YYYY']"
                :value-format="'YYYY-MM-DD'"
                @change="getVouchers()"
              />
            </div>
          </a-col>

          <a-col :span="8">
            <div class="travel-input">
              <label for class="filled">Faixa de valor UTILIZADO</label>
              <a-input-group compact>
                <a-input
                  type="number"
                  style="width: 45%; text-align: center; border-radius: 0"
                  placeholder="Min."
                  v-model="milesCardVouchers.filters.spent.min"
                  @change="getVouchers()"
                />
                a
                <a-input
                  type="number"
                  style="
                    width: 45%;
                    text-align: center;
                    border-left: 0;
                    border-radius: 0;
                  "
                  placeholder="Max"
                  v-model="milesCardVouchers.filters.spent.max"
                  @change="getVouchers()"
                />
              </a-input-group>
            </div>
          </a-col>
        </a-row>
      </a-collapse-panel>
    </a-collapse>

    <a-table
      class="travel-table"
      :columns="[
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          class: 'id',
          sorter: true,
          width: 70,
        },
        {
          title: '',
          scopedSlots: { customRender: 'alert' },
          width: 50,
        },
        {
          title: 'ID Voyage',
          dataIndex: 'voyage_id',
          key: 'voyage_id',
          width: 100,
        },
        {
          title: 'Cliente',
          scopedSlots: { customRender: 'client_name' },
          width: 140,
        },
        {
          title: 'Código do Voucher',
          dataIndex: 'voucher_code',
          key: 'voucher_code',
          width: 190,
        },
        {
          title: 'Valor Total',
          scopedSlots: { customRender: 'value' },
          key: 'value',
          sorter: true,
          width: 120,
        },
        {
          title: 'Disponível',
          scopedSlots: { customRender: 'available_value' },
          key: 'available_value',
          sorter: true,
          width: 120,
        },
        {
          title: 'Utilizado',
          scopedSlots: { customRender: 'spent_value' },
          key: 'spent_value',
          sorter: true,
          width: 120,
        },
        {
          title: 'Validade',
          sorter: true,
          key: 'expires_at',
          scopedSlots: { customRender: 'expires_at' },
          width: 120,
        },
        {
          title: 'Recebimento',
          scopedSlots: { customRender: 'receipt_date' },
          width: 120,
          sorter: true,
          key: 'receipt_date',
        },
        {
          title: 'Última nota',
          scopedSlots: { customRender: 'last_note' },
          width: 120,
        },
        {
          title: 'Tr.',
          scopedSlots: { customRender: 'total_transactions' },
          width: 50,
        },
        {
          title: 'Emissor',
          scopedSlots: { customRender: 'issuer' },
          width: 78,
        },
        {
          title: 'Criado em',
          dataIndex: 'created',
          key: 'created',
          width: 150,
          sorter: true,
        },
        {
          title: '',
          key: 'action',
          align: 'right',
          fixed: 'right',
          width: 60,
          scopedSlots: { customRender: 'action' },
        },
      ]"
      :data-source="milesCardVouchers.list"
      :loading="milesCardVouchers.loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
      @change="vouchersTableChange"
    >
      <div slot="id" slot-scope="text">{{ text }}</div>
      <div slot="voyage_id" slot-scope="text">
        <div v-if="text != '0'">{{ text }}</div>
        <div v-if="text == '0'">---</div>
      </div>
      <div slot="client_name" slot-scope="record">
        <div class="dotted-phrase">{{ record.client_name }}</div>
      </div>
      <div slot="last_note" slot-scope="record">
        {{ record.last_note }}
      </div>

      <div slot="alert" slot-scope="record">
        <a-tooltip v-if="auditVouchersTransactions(record)" placement="top">
          <template slot="title">
            Atenção, este voucher precisar ser auditorado
          </template>
          <a-icon class="corange c-pointer f16" type="warning" />
        </a-tooltip>
      </div>

      <div slot="total_transactions" slot-scope="record">
        {{ record.total_transactions }}
      </div>

      <div slot="issuer" slot-scope="record">
        <a-tooltip v-if="record.issuer">
          <template slot="title">
            {{ record.issuer.first_name }}
            {{ record.issuer.last_name }}
          </template>
          <span id="avatar">
            <a-avatar
              v-if="record.issuer.avatar"
              :src="record.issuer.avatar"
              :size="30"
            />
            <a-avatar
              v-else
              class="upper"
              style="color: #f56a00; background-color: #fde3cf"
            >
              {{ record.issuer.first_name.substring(0, 1)
              }}{{ record.issuer.last_name.substring(0, 1) }}
            </a-avatar>
          </span>
        </a-tooltip>
      </div>
      <div slot="user" slot-scope="record">
        <a-tooltip>
          <template slot="title">
            {{ record.user.first_name }}
            {{ record.user.last_name }}
          </template>
          <span id="avatar">
            <a-avatar
              v-if="record.user.avatar"
              :src="record.user.avatar"
              :size="30"
            />
            <a-avatar
              v-else
              :size="30"
              style="color: #f56a00; background-color: #fde3cf"
            >
              {{ record.user.first_name }}
            </a-avatar>
          </span>
        </a-tooltip>
      </div>
      <div slot="value" slot-scope="record">
        {{ formatPriceCurrency(record.value, record.currency) }}
      </div>
      <div slot="available_value" slot-scope="record">
        {{ formatPriceCurrency(record.available_value, record.currency) }}
      </div>
      <div slot="spent_value" slot-scope="record">
        {{ formatPriceCurrency(record.spent_value, record.currency) }}
      </div>
      <div slot="receipt_date" slot-scope="record">
        <div v-if="record.receipt_date == '0000-00-00'">---</div>
        <div v-if="record.receipt_date != '0000-00-00'">
          {{ formatDate(record.receipt_date) }}
        </div>
      </div>
      <div slot="expires_at" slot-scope="record">
        <div v-if="record.expires_at == '0000-00-00'">---</div>
        <div
          :class="
            record.status == 'Ativo' ||
            record.status == 'Pendente' ||
            record.status == 'Auditoria'
              ? checkExpiration(record.expires_at)
              : ''
          "
          :title="
            checkExpiration(record.expires_at) == 'red fw-600'
              ? 'Vencimento está próximo.'
              : ''
          "
          v-if="record.expires_at != '0000-00-00'"
        >
          {{ formatDate(record.expires_at) }}
        </div>
      </div>
      <template slot="action" slot-scope="record">
        <div class="actions-outer" style="position: relative">
          <a class="edit ml-15" @click="edit(record.id)">
            <a-icon slot="prefix" :type="'eye-svg'" />
          </a>
        </div>
      </template>
    </a-table>

    <div class="a-center mt-30" style="padding-bottom: 100px">
      <a-pagination
        show-size-changer
        :page-size-options="pageSizeOptions"
        :default-current="milesCardVouchers.pagination.page"
        :total="milesCardVouchers.pagination.total"
        :page-size.sync="milesCardVouchers.pagination.perPage"
        @change="changePage"
        @showSizeChange="changePageSize"
      />
    </div>

    <a-modal
      class="miles-card-modal"
      v-model="viewVoucher"
      width="1000px"
      :footer="false"
      @cancel="closeVoucherModal"
    >
      <VouchersModal :voucherId="selectedVoucherId" v-if="viewVoucher" />
    </a-modal>

    <a-drawer
      title="IMPORTAR VOUCHERS"
      placement="right"
      width="900px"
      class="travel-drawer"
      :closable="true"
      :visible="openImportVoucher"
      @close="closeImportVoucher"
    >
      <div>
        <a-alert type="info" class="mb-20" banner style="font-size: 12px">
          <template slot="message">
            <b>1° PASSO: </b> Tenha o arquivo XLSX dentro dos padrões deste
            <b>
              <a
                href="https://api.viajar.tur.br/v1/miles-card-voucher/import-model/vouchers.xlsx"
                target="_blank"
                >modelo</a
              ></b
            >.
          </template>
        </a-alert>

        <a-alert class="mb-20 mt-20" banner style="font-size: 12px">
          <template slot="message">
            <b>ATENÇÃO: </b>
            <br />
            Os formatos dos valores e datas devem estar rigorosamente dentro do
            padrão para evitar erros.

            <br />
            <b>COLUNAS OBRIGATÓRIAS: </b> value | available_value | status |
            currency.
          </template>
        </a-alert>

        <a-alert type="info" class="mb-20" banner style="font-size: 12px">
          <template slot="message">
            <b>2° PASSO: </b> Após padronizar o arquivo XLSX acesse o site
            <a href=" https://cloudconvert.com/xlsx-to-csv" target="_blank"
              >cloudconvert.com</a
            >
            para converter de XLSX para CSV.
          </template>
        </a-alert>

        <a-upload
          v-if="importCSV.step == 1"
          name="file"
          :multiple="false"
          accept=".csv"
          :action="`https://api.viajar.tur.br/v1/miles-card-voucher/import.php?step=${importCSV.step}&user_id=${$store.state.userData.id}&user_name=${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`"
          method="post"
          :showUploadList="false"
          :headers="{
            authorization: `Bearer ${this.$store.state.token}`,
          }"
          @change="uploadedFile"
        >
          <a-button>
            <a-icon type="upload" /> Tudo pronto! Importar o CSV.
          </a-button>
        </a-upload>

        <div v-if="importCSV.step == 2">
          <a-alert
            type="info"
            class="mt-20 mb-20"
            banner
            style="font-size: 12px"
          >
            <template slot="message">
              <b>3° PASSO: </b> Verificar os dados da importação.
            </template>
          </a-alert>

          <a-alert class="mb-20 mt-20" banner style="font-size: 12px">
            <template slot="message">
              <b>ATENÇÃO! </b> Verifique se os campos estão entrando na ordem
              correta. Caso contrário, volte ao primeiro passo.
            </template>
          </a-alert>
          <div class="csv-prev" v-html="importCSV.html" />

          <a-alert type="info" class="mt-20" banner style="font-size: 12px">
            <template slot="message">
              <b>4° PASSO: </b> Selecione o CSV novamente e importe para o banco
              de dados.
            </template>
          </a-alert>

          <a-upload
            name="file"
            :multiple="false"
            accept=".csv"
            :action="`https://api.viajar.tur.br/v1/miles-card-voucher/import.php?step=${importCSV.step}&user_id=${$store.state.userData.id}&user_name=${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`"
            method="post"
            :showUploadList="false"
            :headers="{
              authorization: `Bearer ${this.$store.state.token}`,
            }"
            @change="uploadedFile"
          >
            <a-button type="primary" class="mt-30">
              <a-icon type="upload" /> Importar para o Banco
            </a-button>
          </a-upload>
        </div>
      </div>
    </a-drawer>

    <a-drawer
      title="NOVO VOUCHER"
      placement="right"
      width="640px"
      class="travel-drawer"
      :closable="true"
      :visible="openCreateVoucher"
      @close="openCreateVoucher = false"
    >
      <EditVoucherData
        v-if="openCreateVoucher"
        type="create"
        :fieldSizes="{
          user_id: 12,
          issuer_id: 12,
          customer_id: 12,
          airline_id: 12,
          create_customer: 12,
          voyage_id: 12,
          voyage_value: 12,
          voucher_code: 12,
          currency: 12,
          value: 12,
          expires_at: 12,
          client_name: 12,
          identifier: 12,
          receipt_date: 12,
          status: 12,
        }"
        @listVouchersAfterCreate="listVouchersAfterCreate"
      />
    </a-drawer>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";
import vouchersMixins from "@/mixins/vouchers/vouchersMixins";
import EditVoucherData from "@/components/vouchers/forms/EditVoucherData.vue";
import VouchersModal from "@/components/vouchers/modal/VouchersModal.vue";
import { addDays } from "date-fns";

export default {
  mixins: [formatThings, vouchersMixins],
  components: { EditVoucherData, VouchersModal },
  data() {
    return {
      activeTab: "Ativo",
      importCSV: {
        html: "",
        step: 1,
        preview: true,
        message: "",
      },
      pageSizeOptions: ["10", "30", "50", "100", "200", "500", "1000"],
      openCreateVoucher: false,
      openImportVoucher: false,
      viewVoucher: false,
      selectedVoucherId: 0,
      milesCardVouchers: {
        loading: false,
        details: {},
        list: [],
        meta: {},
        filters: {
          users: {
            list: [],
            selected: [],
          },
          currency: {
            list: [{ name: "BRL" }, { name: "EUR" }, { name: "USD" }],
            selected: [],
          },
          customers: {
            list: [],
            selected: [],
          },
          status: {
            list: [
              {
                label: "Ativo",
                value: "Ativo",
              },
              {
                label: "Auditoria",
                value: "Auditoria",
              },
              {
                label: "Cartão de Crédito",
                value: "Cartão de Crédito",
              },
              {
                label: "Pendente",
                value: "Pendente",
              },
              {
                label: "Perdido",
                value: "Perdido",
              },
              {
                label: "Finalizado",
                value: "Finalizado",
              },
            ],
            selected: [],
          },
          period: {
            selected: [],
          },
          expires: {
            selected: [],
          },
          spent: { min: "", max: "" },
          id: "",
          voyage_id: "",
          voucher_code: "",
          client_name: "",
          receipt_date: "",
          order: "desc",
          orderBy: "created",
        },
        pagination: {
          page: 1,
          perPage: 10,
          total: 0,
          totalPages: 0,
        },
      },
    };
  },
  beforeMount() {
    this.$http.get("/user/list?page=1&per_page=100").then(({ data }) => {
      this.milesCardVouchers.filters.users.list = data.data;
    });

    this.milesCardVouchers.pagination.perPage = 30;

    this.getCustomers();
    this.getVouchers();

    document.title = "Vouchers - HAYA";
  },
  methods: {
    auditVouchersTransactions(voucher) {
      let flag = false,
        spentVal = voucher.spent_value,
        totalTransactions = voucher.total_transactions;

      if (spentVal == 0.0 && totalTransactions > 0) flag = true;

      return flag;
    },
    closeImportVoucher() {
      this.openImportVoucher = false;
      this.importCSV.step = 1;
      this.importCSV.html = "";
      this.getVouchers();
    },
    uploadedFile(response) {
      this.importCSV.html = response.file.response.html;
      this.importCSV.step = response.file.response.step;
      this.$message.success(response.file.response.message);
    },
    checkExpiration(date) {
      let exppirationDate = new Date(`${date} 00:00`),
        today = new Date(),
        flag = "";

      if (
        (exppirationDate.getTime() > today.getTime() &&
          exppirationDate.getTime() < addDays(today, 60).getTime()) ||
        exppirationDate.getTime() < today.getTime()
      )
        flag = "red fw-600";

      return flag;
    },
    closeVoucherModal() {
      this.selectedVoucherId = 0;
      this.getVouchers(this.milesCardVouchers.pagination.page);
    },
    listVouchersAfterCreate() {
      this.openCreateVoucher = false;
      this.getVouchers();
    },
    getCustomers(name) {
      this.$http
        .get(`/customer/list?page=1&per_page=10&s=${name}`)
        .then(({ data }) => {
          this.milesCardVouchers.filters.customers.list = data.data;
        });
    },
    searchCustomers(term) {
      this.getCustomers(term);
    },
    onChangeTabs(tab) {
      this.activeTab = tab;

      setTimeout(() => {
        this.milesCardVouchers.pagination.page = 1;
        this.getVouchers();
      }, 50);
    },
    listVouchers() {
      this.openCreateVoucher = false;
      this.getVouchers();
    },
    edit(id) {
      this.viewVoucher = true;
      this.selectedVoucherId = id;
      id;
    },
  },
};
</script>

<style lang="sass" scoped>
.csv-prev
  background: #fafafa
  overflow: auto
  height: 300px
  padding: 20px 20px
  font-size: 12px
  color: #333333
  margin: 10px 0
</style>
